import { useCallback, useEffect, useRef, useState } from "react";
import { IGameRecord } from "../state/state.type";
import { useRecoilState } from "recoil";
import { gameRecordV2State } from "../state/state";
import {
  getCurrentUtcTimestamp,
  getDaysSince,
  getTime,
  getTimeStamp,
} from "../../../utils/getTime";
import { apiDetail } from "../../../constants/constants";
import { request } from "../../../utils/request";
const HourTime = 24;
const nowTime = getCurrentUtcTimestamp();
export const useGameRecordsV2 = (): {
  fetchData: any;
  gameRecord: IGameRecord[];
  isLoadingMore: boolean;
  reqLen: number;
  newTo: number;
  maxLen: number;
  setNowTo: React.Dispatch<React.SetStateAction<number>>;
} => {
  const [gameRecord, setGameRecord] = useRecoilState(gameRecordV2State);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [newTo, setNowTo] = useState<number>(0);
  const [reqLen, setReqLen] = useState<number>(0);
  const [maxLen, setMaxLen] = useState<number>(0);
  useEffect(() => {
    const startDate = new Date(2024, 0, 24); // 2024.1.24
    const daysSince = getDaysSince(startDate, HourTime);
    setMaxLen(daysSince);
  }, []);
  useEffect(() => {
    fetchData(newTo);
  }, [newTo]);
  const fetchData = useCallback(
    (time: number) => {
      if (isLoadingMore) {
        return;
      }
      setReqLen((pre) => (pre += 1));
      const requestTo = nowTime - time * HourTime * 60 * 60;
      const requestFrom = requestTo - HourTime * 60 * 60;
      // console.log(
      //   time,
      //   `from=${getTime(requestFrom * 1000)}&to=${getTime(requestTo * 1000)}`
      // );
      setIsLoadingMore(true);
      request(`${apiDetail.game_records}?from=${requestFrom}&to=${requestTo}`)
        .then((responseData) => {
          const data = responseData.data;
          if (data.length) {
            const dataFrom = data.map((v: any) => ({
              ...v,
              timestamp: getTimeStamp(v.timestamp),
              timestampSource: v.timestamp,
              timestampStr: getTime(v.timestamp),
            }));
            setGameRecord(dataFrom);
          } else {
            setNowTo((pre) => pre + 1);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoadingMore(false);
        });
    },
    [newTo]
  );
  return {
    fetchData,
    gameRecord,
    isLoadingMore,
    reqLen,
    newTo,
    maxLen,
    setNowTo,
  };
};

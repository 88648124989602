import { useCallback, useEffect, useRef, useState } from "react";
import { IGameRecord } from "../state/state.type";
import { useRecoilState } from "recoil";
import { gameRecordAllState } from "../state/state";
import {
  getCurrentUtcTimestamp,
  getDaysSince,
  getTime,
  getTimeStamp,
} from "../../../utils/getTime";
import { apiDetail } from "../../../constants/constants";
import { request } from "../../../utils/request";
const HourTime = 24;
const nowTime = getCurrentUtcTimestamp();
export const useGameRecordsAll = (): {
  fetchData: any;
  gameRecord: IGameRecord[];
  isLoadingMore: boolean;
  reqLen: number;
  newTo: number;
  maxLen: number;
  setNowTo: React.Dispatch<React.SetStateAction<number>>;
  onSearch: any;
} => {
  const [gameRecordAll, setGameRecordAll] = useRecoilState(gameRecordAllState);
  const [gameRecord, setGameRecord] = useState<IGameRecord[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [newTo, setNowTo] = useState<number>(0);
  const [reqLen, setReqLen] = useState<number>(0);
  const [maxLen, setMaxLen] = useState<number>(0);
  useEffect(() => {
    const startDate = new Date(2024, 0, 24); // 2024.1.24
    const daysSince = getDaysSince(startDate, HourTime);
    setMaxLen(daysSince);
    if (daysSince && daysSince >= newTo) {
      fetchData(newTo, daysSince);
    }
  }, []);
  useEffect(() => {
    if (maxLen && !isLoadingMore) {
      const requestTo = nowTime - newTo * HourTime * 60 * 60;
      const requestFrom = requestTo - HourTime * 60 * 60;
      // console.log(
      //   3333,
      //   nowTime,
      //   gameRecordAll.length,
      //   `from=${getTime(requestFrom * 1000)}&to=${getTime(requestTo * 1000)}`
      // );
      setGameRecord(
        gameRecordAll.filter(
          (v) => v.timestamp >= requestFrom && v.timestamp <= requestTo
        )
      );
    }
  }, [newTo, isLoadingMore, gameRecordAll]);
  const fetchData = useCallback(
    (time: number, maxLen: number) => {
      // console.log({ maxLen });
      if (maxLen && maxLen >= time) {
        if (isLoadingMore) {
          return;
        }
        setReqLen((pre) => (pre += 1));
        const requestTo = nowTime - time * HourTime * 60 * 60;
        const requestFrom = requestTo - HourTime * 60 * 60;
        setIsLoadingMore(true);
        // console.log(
        //   time,
        //   `from=${getTime(requestFrom * 1000)}&to=${getTime(requestTo * 1000)}`
        // );
        request(`${apiDetail.game_records}?from=${requestFrom}&to=${requestTo}`)
          .then((responseData) => {
            const data = responseData.data;
            if (data.length) {
              const dataFrom = data.map((v: any) => ({
                ...v,
                timestamp: getTimeStamp(v.timestamp),
                timestampSource: v.timestamp,
                timestampStr: getTime(v.timestamp),
              }));
              setGameRecordAll((pre) => {
                const keyStr = (k: any) =>
                  `${k.dealer}${k.player}${k.timestamp}`;
                const hashSet = new Set(pre.map((item: any) => keyStr(item)));

                const newData = dataFrom.filter((item: any) => {
                  if (!hashSet.has(keyStr(item))) {
                    hashSet.add(keyStr(item));
                    return true;
                  }
                  return false;
                });
                const mergedData = [...pre, ...newData].sort(
                  (a: any, b: any) => b.timestamp - a.timestamp
                );
                return mergedData;
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          })
          .finally(() => {
            // setNowTo((pre) => pre + 1);/
            fetchData(time + 1, maxLen);
          });
      } else {
        setIsLoadingMore(false);
      }
    },
    [newTo]
  );
  const onSearch = useCallback(
    (value: string) => {
      if (value && value.length > 0) {
        setMaxLen(0);
        setGameRecord(
          gameRecordAll.filter(
            (v) =>
              v.address &&
              v.address.toLowerCase().indexOf(value.toLowerCase()) > -1
          )
        );
      } else {
        const startDate = new Date(2024, 0, 24); // 2024.1.24
        const daysSince = getDaysSince(startDate, HourTime);
        setMaxLen(daysSince);
        setNowTo(0);
        const requestTo = nowTime - 0 * HourTime * 60 * 60;
        const requestFrom = requestTo - HourTime * 60 * 60;
        setGameRecord(
          gameRecordAll.filter(
            (v) => v.timestamp >= requestFrom && v.timestamp <= requestTo
          )
        );
      }
    },
    [JSON.stringify(gameRecordAll)]
  );
  return {
    fetchData,
    gameRecord,
    isLoadingMore,
    reqLen,
    newTo,
    maxLen,
    setNowTo,
    onSearch: onSearch,
  };
};

import { isEqual } from "lodash";
import { memo } from "react";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import { useFlip } from "../../components/Card/useFlip";
import InfoListAll from "./components/InfoList/InfoListAll/InfoListAll";

const DashboardPageAll = memo(() => {
  useFlip();
  return (
    <>
      <InfoListAll />
      <ScrollToTopButton />
    </>
  );
}, isEqual);
export default DashboardPageAll;

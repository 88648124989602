import BannerHome from "./components/BannerHome/BannerHome";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import ShowHome from "./components/ShowHome/ShowHome";
import CardHome from "./components/CardHome/CardHome";
import HomeFooter from "./components/HomeFooter/HomeFooter";
import Leaderboard from "./components/Leaderboard/Leaderboard";

const DashboardPage = () => {
  return (
    <>
      <BannerHome />
      <Leaderboard />
      <ShowHome />
      <CardHome />
      <HomeFooter />
      <ScrollToTopButton />
    </>
  );
};
export default DashboardPage;

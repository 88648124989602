import { isEqual } from "lodash";
import { memo } from "react";
import "./ShowHome.scss";
const ShowHome = memo(() => {
  return (
    <div className="show_home">
      <div className="show_home_bg" />
      <div className="show_home_inner">
        <img
          src="/img/homePage/mobile.png"
          alt="mobile"
          className="show_mobile"
        />
        <img
          src="/img/homePage/card01.png"
          alt="card01"
          className="show_card01"
        />
        <img
          src="/img/homePage/card02.png"
          alt="card02"
          className="show_card02"
        />
        <img src="/img/homePage/bg.png" alt="bg" className="show_bg" />
      </div>
    </div>
  );
}, isEqual);
export default ShowHome;

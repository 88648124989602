import { useEffect } from "react";

export const useFlip = () => {
  useEffect(() => {
    const cards = document.querySelectorAll(".card_swap");
    const timer = setInterval(() => {
      const counter = Math.floor(Math.random() * cards.length);
      if (cards.length > 0) {
        cards[counter].classList.add("flipped");
      }
    }, 500);

    return () => {
      clearInterval(timer); // 清除定时器，避免内存泄漏
    };
  }, []);
};

import { isEqual } from "lodash";
import { memo, useState } from "react";
import Button from "../../../../../components/Button/Button";
import "./Search.scss";
import { IGameRecord } from "../../../state/state.type";
const Search = memo(
  ({ onSearch, gameRecord }: { onSearch: any; gameRecord: IGameRecord[] }) => {
    const [value, setValue] = useState("");
    return (
      <div className="input_boxWrap">
        <div className="input_box">
          <div className="input_box_input">
            <input
              type="text"
              value={value}
              onChange={(e) => {
                const { value } = e.target;
                setValue(value);
                onSearch(value);
              }}
              placeholder={"Address"}
            />
          </div>
          <Button
            label={"Search"}
            onClick={() => onSearch(value)}
            isOn={false}
          />
        </div>
        {value ? (
          <p className="total">Total Len: {gameRecord.length}</p>
        ) : (
          <p className="total"> </p>
        )}
      </div>
    );
  },
  isEqual
);
export default Search;

import { isEqual } from "lodash";
import { memo } from "react";
import { Link } from "react-router-dom";
import "./Banner.scss";
import { useIsMobile } from "../../../../hooks/useIsMobile";
const Banner = memo(() => {
  const isMobile = useIsMobile();
  return (
    <div className="banner">
      <img
        src={`/img/dashboardPage/banner${isMobile ? "_m" : ""}.png`}
        alt="banner"
        className="banner_bg"
      />
      <Link to="/" className="banner_title">
        <img
          src="/img/dashboardPage/banner_title.png"
          alt="HyprJack Game Browser"
        />
        <img
          src="/img/dashboardPage/star.png"
          className="banner_star"
          alt="stat"
        />
        {/* <img
          src="/img/dashboardPage/star.png"
          className="banner_star02"
          alt="stat"
        /> */}
      </Link>
      <div className="banner_bg_div" />
    </div>
  );
}, isEqual);
export default Banner;

import { atom } from "recoil";
import { localStorageEffect } from "../../../utils/localStorageEffect";
import { IGameMetrics, IGameRecord, initGameMetrics } from "./state.type";
export const gameMericsState = atom<IGameMetrics>({
  key: "gameMerics",
  default: initGameMetrics,
  effects_UNSTABLE: [localStorageEffect("gameMerics")],
});
export const gameRecordState = atom<IGameRecord[]>({
  key: "gameRecord",
  default: [],
  effects_UNSTABLE: [localStorageEffect("gameRecord")],
});

export const gameRecordV2State = atom<IGameRecord[]>({
  key: "gameRecordV2",
  default: [],
  // effects_UNSTABLE: [localStorageEffect("gameRecordV2")],
});

export const gameRecordAllState = atom<IGameRecord[]>({
  key: "gameRecordAll",
  default: [],
  effects_UNSTABLE: [localStorageEffect("gameRecordAll")],
});

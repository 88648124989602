import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { gameMericsState } from "../state/state";
import { request } from "../../../utils/request";
import { apiDetail } from "../../../constants/constants";
import { IGameMetrics } from "../state/state.type";
import { isEqual } from "lodash";

export const useGameMetrics = (): { gameMerics: IGameMetrics } => {
  const [gameMerics, setGameMerics] = useRecoilState(gameMericsState);
  const fetchData = useCallback(async () => {
    try {
      const result = await request(apiDetail.game_metrics);
      const obj = {} as IGameMetrics;
      const arr = Object.keys(gameMerics);
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i]] = result.data[arr[i]] ?? "0";
      }
      if (!isEqual(obj, gameMerics)) {
        setGameMerics(obj);
      }
    } catch (e: any) {}
  }, [JSON.stringify(gameMerics)]);
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(gameMerics)]);
  return { gameMerics };
};

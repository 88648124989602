import { isEqual } from "lodash";
import { memo } from "react";
import "./BannerHome.scss";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import Button from "../../../../components/Button/Button";
const BannerHome = memo(() => {
  const isMobile = useIsMobile();
  return (
    <div className="bannerHome">
      <img
        src={`/img/homePage/banner_bg${isMobile ? "_m" : ""}.png`}
        alt="banner"
        className="banner_bg"
      />
      <div className="banner_title">
        <img
          src="/img/homePage/gold_logo_new.png"
          alt="HyprJack"
          className="logo"
        />
        <img
          src="/img/dashboardPage/star.png"
          className="banner_star"
          alt="stat"
        />
        {/* <img
          src="/img/dashboardPage/star.png"
          className="banner_star02"
          alt="stat"
        /> */}
        <p>
          Introducing HyprJack,
          <br />
          your ultimate AI-driven Telegram bot for Blackjack
        </p>
        <a
          href="https://t.me/HyprJackBot"
          target="_blank"
          rel="noreferrer"
          className="banner_tg imageContainerWaves"
        >
          <Button isOn={true} label={"Play Now"} />
        </a>
      </div>
      <div className="banner_bg_div" />
    </div>
  );
}, isEqual);
export default BannerHome;

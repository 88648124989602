import { useRecoilState } from "recoil";
import { isMobileState } from "../state/global";

export const useIsMobile = (): boolean => {
  const [isMobile] = useRecoilState(isMobileState);
  if (isMobile === undefined) {
    return false;
  }
  return isMobile;
};

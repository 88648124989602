import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { request } from "../../../utils/request";
import { apiDetail } from "../../../constants/constants";
import { isEqual } from "lodash";
import { LeaderboardState } from "../state/state";
import { ILeaderboard } from "../state/state.type";
import { formatMoney } from "../../../utils/formatMoney";
import { getShortStr } from "../../../utils/getShorStr";
export const useLeaderboard = (): { leaderboard: ILeaderboard[] } => {
  const [leaderboard, setLeaderboard] = useRecoilState(LeaderboardState);

  const fetchData = useCallback(async () => {
    try {
      const result = await request(apiDetail.airdrop_top_list);
      const _data = result.data.map((v: any, index: number) => ({
        ...v,
        index: index + 1,
        addressStr: getShortStr(v.address),
        airdropStr: formatMoney(v.airdrop),
      }));

      if (!isEqual(_data, leaderboard)) {
        setLeaderboard(_data);
      }
    } catch (e: any) {}
  }, [JSON.stringify(leaderboard)]);

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [JSON.stringify(leaderboard)]);

  return { leaderboard };
};

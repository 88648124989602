import { isEqual } from "lodash";
import { memo } from "react";
import Title from "../../title/title";
import { cardNumberStr, colorStyle } from "../../../../../utils/cardImgPath";
import Loading from "../../../../../components/Loading/Loading";
import { IGameRecord } from "../../../state/state.type";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { getShortStr } from "../../../../../utils/getShorStr";
import Card from "../../../../../components/Card/Card";
import { useGameRecordsV2 } from "../../../hooks/useGameRecordsV2";
import Pagination from "../../../../../components/Pagination/Paginate";
const InfoListV2 = memo(() => {
  const isMobile = useIsMobile();
  const {
    fetchData,
    gameRecord,
    reqLen,
    newTo,
    maxLen,
    setNowTo,
    isLoadingMore,
  } = useGameRecordsV2();

  return (
    <div className="infoListWrap">
      <Title label={"Card types appearing in each round"} />
      <div className="responsive-tableV2">
        {isLoadingMore ? (
          <Loading isLoading={true} />
        ) : isMobile ? (
          <Table_M gameRecord={gameRecord} />
        ) : (
          <Table gameRecord={gameRecord} />
        )}
      </div>
      {isLoadingMore ? null : (
        <Pagination
          totalPages={maxLen}
          currentPage={newTo}
          onPageChange={function (page: number): void {
            if (page < maxLen) {
              setNowTo(page);
            }
          }}
        />
      )}
    </div>
  );
}, isEqual);
const Table = memo(({ gameRecord }: { gameRecord: IGameRecord[] }) => {
  return (
    <div className="responsive-table-inner">
      <div className="table-header">
        {["Time", "Player", "Dealer", "Hash"].map((v) => (
          <div className="table-cell" key={v}>
            <p>{v}</p>
            <div className="table-line" />
          </div>
        ))}
      </div>
      <div className="table-body">
        {gameRecord.map((item, index) => (
          <div className="table-row" key={index}>
            <div className="table-cell">
              <p className="text">{item.timestampStr}</p>
            </div>
            <div className="table-cell">
              <div className="cardItemWrap">
                <CardItem cardNumber={item.player} />
              </div>
            </div>
            <div className="table-cell">
              <div className="cardItemWrap">
                <CardItem cardNumber={item.dealer} />
              </div>
            </div>
            <div className="table-cell">
              <p className="text">{item.hash}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}, isEqual);
const Table_M = memo(({ gameRecord }: { gameRecord: IGameRecord[] }) => {
  return (
    <div className="table-m-border">
      {gameRecord.map((item, index) => (
        <div className="table-m-row" key={index}>
          <div className="table-m-header">
            <p className="text">
              <strong>Hash:</strong>
              <em>{getShortStr(item.hash)}</em>
            </p>
            <p className="text">{item.timestampStr}</p>
          </div>
          <div className="table-m-body">
            <div className="fl">
              <h2>Player</h2>
              <CardItem cardNumber={item.player} />
            </div>
            <div className="fl">
              <h2>Dealer</h2>
              <CardItem cardNumber={item.dealer} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}, isEqual);
const CardItem = memo(({ cardNumber }: { cardNumber: string[] }) => {
  return (
    <div className="cardItemWrap">
      {cardNumber.map((keys) => (
        <div className={`cardItem ${colorStyle(keys)}`} key={keys}>
          <Card cardKeyStr={keys} />
          <h3>{cardNumberStr(keys)}</h3>
        </div>
      ))}
    </div>
  );
}, isEqual);
export default InfoListV2;

import React, { useState, useEffect } from "react";
import { cardImgPath } from "../../utils/cardImgPath";
import "./Card.scss";
const Card = ({ cardKeyStr }: { cardKeyStr: string }) => {
  return (
    <div className={`card_swap`}>
      <img
        className="card-front"
        src={cardImgPath(cardKeyStr)}
        alt={cardKeyStr}
      />
      <img
        className="card-back"
        src={cardImgPath(cardKeyStr)}
        alt={cardKeyStr}
      />
    </div>
  );
};

export default Card;

import { isEqual } from "lodash";
import { memo } from "react";
import Banner from "./components/Banner/Banner";
import Border from "./components/Border/Border";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import { useFlip } from "../../components/Card/useFlip";
import InfoList from "./components/InfoList/InfoList";

const DashboardPage = memo(() => {
  useFlip();
  return (
    <>
      <Banner />
      <Border />
      <InfoList />
      <ScrollToTopButton />
    </>
  );
}, isEqual);
export default DashboardPage;

import { isEqual } from "lodash";
import React, { memo, useMemo } from "react";
import "./Leaderboard.scss";
import { useLeaderboard } from "../../hooks/useLeaderboard";
import { ILeaderboard } from "../../state/state.type";
const Leaderboard = memo(() => {
  const { leaderboard } = useLeaderboard();
  const len = 5;
  return (
    <div className="leaderboard">
      <h2>Leaderboard</h2>
      <div className="leaderboard_border">
        <ul>
          {leaderboard.slice(0, len).map((v, index) => (
            <Item key={index} item={v} />
          ))}
        </ul>
        <ul>
          {leaderboard.slice(len, 11).map((v, index) => (
            <Item key={index} item={v} />
          ))}
        </ul>
      </div>
    </div>
  );
}, isEqual);
const Item = memo(({ item }: { item: ILeaderboard }) => {
  const Pre = useMemo(() => {
    if (item.index < 4) {
      return (
        <img
          className="leaderboard_logo"
          src={`/img/homePage/rank${item.index}.png`}
          alt=""
        />
      );
    }
    return <p className="leaderboard_logo">{item.index}</p>;
  }, [item.index]);
  return (
    <li>
      <div className="leaderboard_fr">
        {Pre}
        <p className="leaderboard_address">{item.addressStr}</p>
      </div>
      <p className="leaderboard_number">{item.airdropStr}</p>
    </li>
  );
}, isEqual);
export default Leaderboard;

import { isEqual } from "lodash";
import { Suspense, memo } from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import HomePage from "../view/homePage/homePage";
import DashboardPage from "../view/dashboardPage/dashboardPage";
import { useInit } from "../hooks/useInit";
import { RecoilRoot } from "recoil";
import { IsMobileProvider } from "../provider/IsMobileProvider/IsMobileProvider";
import Header from "../components/Header/Header";
import DashboardPageAll from "../view/dashboardPage/dashboardPageAll";

const RouterIndex = memo(() => {
  useInit();
  return (
    <Suspense fallback={<></>}>
      <RecoilRoot>
        <IsMobileProvider>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/bwmvj" element={<DashboardPageAll />} />
            </Routes>
          </BrowserRouter>
        </IsMobileProvider>
      </RecoilRoot>
    </Suspense>
  );
}, isEqual);
export default RouterIndex;

import { isEqual } from "lodash";
import { memo } from "react";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import InfoListV1 from "./InfoListV1/InfoListV1";
import InfoListV2 from "./InfoListV2/InfoListV2";

const InfoList = memo(() => {
  const isM = useIsMobile();
  if (isM) {
    return <InfoListV1 />;
  }
  return <InfoListV2 />;
}, isEqual);
export default InfoList;

export const cardImgPath = (str: string): string => {
  if (str.toLowerCase().indexOf("clubs") > -1) {
    return "/img/icon/clubs.svg";
  }
  if (str.toLowerCase().indexOf("diamonds") > -1) {
    return "/img/icon/diamonds.svg";
  }
  if (str.toLowerCase().indexOf("hearts") > -1) {
    return "/img/icon/hearts.svg";
  }
  if (str.toLowerCase().indexOf("spades") > -1) {
    return "/img/icon/spades.svg";
  }
  return "";
};

export const cardNumberStr = (str: string): string => {
  if (str.toLowerCase().indexOf("two") > -1) {
    return "2";
  }
  if (str.toLowerCase().indexOf("three") > -1) {
    return "3";
  }
  if (str.toLowerCase().indexOf("four") > -1) {
    return "4";
  }

  if (str.toLowerCase().indexOf("five") > -1) {
    return "5";
  }

  if (str.toLowerCase().indexOf("six") > -1) {
    return "6";
  }
  if (str.toLowerCase().indexOf("seven") > -1) {
    return "7";
  }
  if (str.toLowerCase().indexOf("eight") > -1) {
    return "8";
  }
  if (str.toLowerCase().indexOf("nine") > -1) {
    return "9";
  }
  if (str.toLowerCase().indexOf("ten") > -1) {
    return "10";
  }
  if (str.toLowerCase().indexOf("jack") > -1) {
    return "J";
  }
  if (str.toLowerCase().indexOf("queen") > -1) {
    return "Q";
  }
  if (str.toLowerCase().indexOf("king") > -1) {
    return "K";
  }
  if (str.toLowerCase().indexOf("ace") > -1) {
    return "A";
  }
  return "";
};
export const colorStyle = (str: string): string => {
  if (str.toLowerCase().indexOf("clubs") > -1) {
    return "white";
  }
  if (str.toLowerCase().indexOf("hearts") > -1) {
    return "red";
  }
  if (str.toLowerCase().indexOf("spades") > -1) {
    return "white";
  }
  if (str.toLowerCase().indexOf("diamonds") > -1) {
    return "red";
  }
  return "";
};

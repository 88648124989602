import { isEqual } from "lodash";
import { memo } from "react";
import "./HomeFooter.scss";
const HomeFooter = memo(() => {
  return (
    <div className="home_footer">
      <p>Exclusively on</p>
      <a href="https://www.hypr.network" target="_blank">
        <img src="/img/homePage/hypr_logo.svg" alt="hypr logo" />
      </a>
    </div>
  );
}, isEqual);
export default HomeFooter;

import { isEqual } from "lodash";
import { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button/Button";
import "./Header.scss";
import { useIsMobile } from "../../hooks/useIsMobile";
const Header = memo(() => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const isPathLocation = useMemo(() => {
    const arr = location.pathname.split("/");
    return "/" + arr[1];
  }, [location]);
  return (
    <div className="header">
      <div className="header_inner">
        <Link to="/">
          <img
            src={`/img/${isMobile ? "logo.svg" : "logo_header.png"}`}
            alt="HyprJack"
            className="header_logo"
          />
        </Link>
        <div className="header_fr">
          {[
            {
              label: "Dashboard",
              link: "/dashboard",
              target: "_self",
            },
            {
              label: "FAQ",
              link: "https://docs.hypr.network/apps/hyprjack",
              target: "_blank",
            },
          ].map((v) => (
            <Link
              key={v.label}
              to={v.link}
              target={v.target}
              className={`header_nav ${
                isPathLocation != "/" && v.link.indexOf(isPathLocation) > -1
                  ? "header_nav_on"
                  : ""
              }`}
            >
              {v.label}
            </Link>
          ))}
          <a
            href="https://t.me/HyprJackBot"
            target="_blank"
            rel="noreferrer"
            className="header_tg"
          >
            <Button isOn={true} label="Play Now" />
          </a>
        </div>
      </div>
    </div>
  );
}, isEqual);
export default Header;

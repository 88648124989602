import React, { useState, useEffect } from "react";
import "./ScrollToTopButton.scss";
const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 100 && !showButton) {
        setShowButton(true);
      } else if (scrollY <= 100 && showButton) {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* 页面内容 */}

      {showButton && (
        <div onClick={scrollToTop} className="scrollToTop">
          <img src="/img/icon/arrow-up.svg" alt="up" />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;

export const getShortStr = (
  text: string,
  front: number = 5,
  back: number = 5
): string => {
  let result: string;

  if (text.length >= front + back) {
    result =
      text.substring(0, front) + "..." + text.substring(text.length - back);
  } else if (text.length > front) {
    result = text.substring(0, front) + "..." + text.substring(0, text.length);
  } else {
    result = text;
  }

  return result;
};

// clubs hearts spades diamonds
// :梅花: :红心: :黑桃: :菱形:
export const initGameMetrics = {
  ClubsTwo: "0",
  HeartsTwo: "0",
  SpadesTwo: "0",
  DiamondsTwo: "0",
  ClubsThree: "0",
  HeartsThree: "0",
  SpadesThree: "0",
  DiamondsThree: "0",
  ClubsFour: "0",
  HeartsFour: "0",
  SpadesFour: "0",
  DiamondsFour: "0",
  ClubsFive: "0",
  HeartsFive: "0",
  SpadesFive: "0",
  DiamondsFive: "0",
  ClubsSix: "0",
  HeartsSix: "0",
  SpadesSix: "0",
  DiamondsSix: "0",
  ClubsSeven: "0",
  HeartsSeven: "0",
  SpadesSeven: "0",
  DiamondsSeven: "0",
  ClubsEight: "0",
  HeartsEight: "0",
  SpadesEight: "0",
  DiamondsEight: "0",
  ClubsNine: "0",
  HeartsNine: "0",
  SpadesNine: "0",
  DiamondsNine: "0",
  ClubsTen: "0",
  HeartsTen: "0",
  SpadesTen: "0",
  DiamondsTen: "0",
  ClubsJack: "0",
  HeartsJack: "0",
  SpadesJack: "0",
  DiamondsJack: "0",
  ClubsQueen: "0",
  HeartsQueen: "0",
  SpadesQueen: "0",
  DiamondsQueen: "0",
  ClubsKing: "0",
  HeartsKing: "0",
  SpadesKing: "0",
  DiamondsKing: "0",
  ClubsAce: "0",
  HeartsAce: "0",
  SpadesAce: "0",
  DiamondsAce: "0",
};
export type IGameMetrics = Record<string, string>;
export type IGameRecord = {
  dealer: string[]; //"SpadesAce",
  hash: string;
  player: string[];
  timestamp: number; //"Wed, 03 Jan 2024 03:54:57 +0000"
  timestampSource: string; //"Wed, 03 Jan 2024 03:54:57 +0000"
  timestampStr: string; //"Wed, 03 Jan 2024 03:54:57 +0000"

  address: string;
  airdrop_this_time: number;
  balance_after: string;
  balance_before: string;
  bet_amount: string;
  dealer_score: number;
  games_played: number;
  is_double: boolean;

  player_result: string;
  player_score: number;
  total_airdrop: number;
};

import { isEqual } from "lodash";
import { memo } from "react";
import "./Button.scss";
const Button = memo(
  ({
    isOn,
    label,
    onClick,
  }: {
    isOn: boolean;
    label: string;
    onClick?: any;
  }) => {
    return (
      <button
        className={`${isOn ? "btn_on" : ""} btn_primary`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  },
  isEqual
);
export default Button;

import { isEqual } from "lodash";
import { memo } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";
interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}
const Paginate: React.FC<PaginationProps> = memo(
  ({ totalPages, currentPage, onPageChange }) => {
    const handlePageClick = (event: { selected: number }) => {
      // const newOffset = ( * itemsPerPage) % totalPages;
      console.log(
        `User requested page number ${event.selected}, which is offset `
      );
      onPageChange(event.selected);
    };

    return (
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <>
            <img src="/img/dashboardPage/chevron-right.svg" alt="right" />
          </>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel={
          <>
            <img src="/img/dashboardPage/chevron-left.svg" alt="left" />
          </>
        }
        renderOnZeroPageCount={null}
        className="pagination"
      />
    );
  },
  isEqual
);
export default Paginate;

import { useCallback, useEffect, useRef, useState } from "react";
import { IGameRecord } from "../state/state.type";
import { useRecoilState } from "recoil";
import { gameRecordState } from "../state/state";
import {
  getCurrentUtcTimestamp,
  getTime,
  getTimeStamp,
} from "../../../utils/getTime";
import { apiDetail } from "../../../constants/constants";
import { request } from "../../../utils/request";
import { isEqual, isNumber } from "lodash";

export const useGameRecordsV1 = (): {
  fetchData: any;
  gameRecord: IGameRecord[];
  isLoadingMore: boolean;
  reqLen: number;
  newTo: number;
} => {
  const [gameRecord, setGameRecord] = useRecoilState(gameRecordState);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [newTo, setNowTo] = useState<number>(getCurrentUtcTimestamp());
  const [reqLen, setReqLen] = useState<number>(0);

  const fetchData = useCallback(
    (time: number) => {
      if (isLoadingMore) {
        return;
      }
      setReqLen((pre) => (pre += 1));
      const requestTo = time;
      const requestFrom = requestTo - 8 * 60 * 60;
      // 2024-01-22 02:15:54
      // 2024-01-22 10:15:54
      console.log(time, `from=${requestFrom}&to=${requestTo}`);
      setIsLoadingMore(true);
      request(`${apiDetail.game_records}?from=${requestFrom}&to=${requestTo}`)
        .then((responseData) => {
          const data = responseData.data;
          if (data.length) {
            const dataFrom = data.map((v: any) => ({
              ...v,
              timestamp: getTimeStamp(v.timestamp),
              timestampSource: v.timestamp,
              timestampStr: getTime(v.timestamp),
            }));
            console.log({ dataFrom });
            setGameRecord((pre) => {
              const keyStr = (k: any) => `${k.dealer}${k.player}${k.timestamp}`;
              const hashSet = new Set(pre.map((item: any) => keyStr(item)));

              const newData = dataFrom.filter((item: any) => {
                if (!hashSet.has(keyStr(item))) {
                  hashSet.add(keyStr(item));
                  return true;
                }
                return false;
              });
              const mergedData = [...pre, ...newData].sort(
                (a: any, b: any) => b.timestamp - a.timestamp
              );
              return mergedData;
            });
            // setGameRecord((pre) => [...pre, ...dataFrom]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setNowTo(requestFrom);
          setIsLoadingMore(false);
        });
    },
    [newTo]
  );
  // console.log({ gameRecord });
  return {
    fetchData,
    gameRecord,
    isLoadingMore,
    reqLen,
    newTo,
  };
};

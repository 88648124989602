import { isEqual } from "lodash";
import { memo } from "react";
import "./CardHome.scss";
const CardHome = memo(() => {
  return (
    <div className="cardHome">
      {[
        {
          logo: "card_card01.png",
          label: "How to play",
          content:
            "The primary goal of the game is to achieve a score as close to 21 points as possible, without surpassing the elusive 21. Engage in the thrill of the game by using either $HYPR or $ETH as your chosen currency.",
        },
        {
          logo: "card_card02.png",
          label: "Rewards",
          content:
            "Should your skills prove victorious, your winnings will be rewarded in the same cryptocurrency denomination, adding an exciting crypto twist to your triumphs.",
        },
        {
          logo: "card_card03.png",
          label: "Where to play",
          content:
            'Simply add <a href="https://t.me/HyprJackBot" target="_blank">@HyprJackBot</a> to your Telegram account and start playing.',
        },
        {
          logo: "card_card04.png",
          label: "HyprJack insights",
          content:
            'Interested in following game metrics? Visit the <a href="/dashboard">Dashboard</a> to learn more. Have questions? Visit the <a href="https://docs.hypr.network/apps/hyprjack" target="_blank">FAQ</a> for more info.',
        },
      ].map((v) => (
        <div key={v.logo} className="cardHome_item">
          <img src={`/img/homePage/${v.logo}`} alt={v.label} />
          <h3>{v.label}</h3>
          <p dangerouslySetInnerHTML={{ __html: v.content }} />
        </div>
      ))}
    </div>
  );
}, isEqual);
export default CardHome;

// const dateString = "Wed, 03 Jan 2024 03:54:57 +0000";
export const getTime = (dateString: string | number) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const formattedDateTimeString = `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTimeString;
};

export const getTimeStamp = (dateString: string): number => {
  const date = new Date(dateString);

  const utcTimestamp = date.getTime();
  return Math.floor(utcTimestamp / 1000);
};
export const getCurrentUtcTimestamp = () => {
  const currentDate = new Date();
  const utcTimestamp = currentDate.getTime();
  return Math.floor(utcTimestamp / 1000);
};

// 获取从指定日期到现在的天数
export function getDaysSince(startDate: Date, housrs: number): number {
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - startDate.getTime();
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * housrs));
  return days;
}

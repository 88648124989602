import { isEqual } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import Title from "../title/title";
import { useGameMetrics } from "../../hooks/useGameMetrics";
import "swiper/css";
import "./swiper.pagination.scss";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IGameMetrics } from "../../state/state.type";
import {
  cardImgPath,
  cardNumberStr,
  colorStyle,
} from "../../../../utils/cardImgPath";
import "./Border.scss";
import Card from "../../../../components/Card/Card";
const Border = memo(() => {
  const { gameMerics } = useGameMetrics();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const groupSize = useMemo(() => {
    if (windowWidth < 768) {
      return 10;
    } else if (windowWidth < 1200) {
      return 14;
    } else {
      return 22;
    }
  }, [windowWidth]);
  const dataList = useMemo(() => {
    const keys = Object.keys(gameMerics);
    const groups = [];
    for (let i = 0; i < keys.length; i += groupSize) {
      const group = keys.slice(i, i + groupSize).reduce((acc, key) => {
        acc[key] = gameMerics[key];
        return acc;
      }, {} as IGameMetrics);
      groups.push(group);
    }
    return groups;
  }, [JSON.stringify(gameMerics), groupSize]);
  return (
    <div className="borderWrap">
      <Title label={"Probability of each card appearing"} />
      <Swiper
        direction="horizontal"
        loop={true}
        autoplay={{
          delay: 20000,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        spaceBetween={30}
        centeredSlides={true}
        navigation={false}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="borderstyled"
      >
        {dataList.map((v: IGameMetrics, index) => (
          <SwiperSlide key={index} className="borderSwiperWrap">
            <div className="borderSwiper">
              {Object.keys(v)
                .slice(0, groupSize / 2)
                .map((vv) => (
                  <SwiperItem key={vv} keys={vv} value={v[vv]} />
                ))}
            </div>
            <div className="borderSwiper">
              {Object.keys(v)
                .slice(groupSize / 2)
                .map((vv) => (
                  <SwiperItem key={vv} keys={vv} value={v[vv]} />
                ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}, isEqual);
const SwiperItem = memo(({ keys, value }: { keys: string; value: string }) => {
  return (
    <div className={`swiperItem ${colorStyle(keys)}`}>
      <div className="top">
        <Card cardKeyStr={keys} />
        <h3>{cardNumberStr(keys)}</h3>
      </div>
      <div className="bottom">
        <p>{value}</p>
      </div>
    </div>
  );
}, isEqual);
export default Border;

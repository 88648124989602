import { isEqual } from "lodash";
import { memo } from "react";
import Title from "../../title/title";
import { cardNumberStr, colorStyle } from "../../../../../utils/cardImgPath";
import { IGameRecord } from "../../../state/state.type";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { getShortStr } from "../../../../../utils/getShorStr";
import Card from "../../../../../components/Card/Card";
import { useGameRecordsAll } from "../../../hooks/useGameRecordsAll";
import Paginate from "../../../../../components/Pagination/Paginate";
import Search from "./Search";
import Loading from "../../../../../components/Loading/Loading";
const InfoListAll = memo(() => {
  const { gameRecord, isLoadingMore, maxLen, newTo, setNowTo, onSearch } =
    useGameRecordsAll();

  return (
    <div className="infoListWrap">
      <Title label={"IIAdminII"} />
      <Search onSearch={onSearch} gameRecord={gameRecord} />
      <div
        className={maxLen === 0 ? "responsive-table" : "responsive-tableAll"}
      >
        {isLoadingMore ? (
          <Loading isLoading={true} />
        ) : (
          <Table_M gameRecord={gameRecord} />
        )}
      </div>
      {maxLen === 0 || isLoadingMore ? null : (
        <Paginate
          totalPages={maxLen}
          currentPage={newTo}
          onPageChange={function (page: number): void {
            if (page < maxLen) {
              setNowTo(page);
            }
          }}
        />
      )}
    </div>
  );
}, isEqual);
const Table_M = memo(({ gameRecord }: { gameRecord: IGameRecord[] }) => {
  return (
    <div className="table-m-border">
      {gameRecord.map((item, index) => (
        <div className="table-m-row" key={index}>
          <div className="table-m-header">
            <p className="text">
              <strong>Hash:</strong>
              <em>{getShortStr(item.hash)}</em>
            </p>
            <p className="text">
              <strong>address:</strong>
              <em>{item.address}</em>
            </p>
            <p className="text">{item.timestampStr}</p>
          </div>
          <div className="table-m-body">
            <div className="fl">
              <h2>Player</h2>
              <CardItem cardNumber={item.player} />
              <h2>airdrop_this_time</h2>
              <p>{item.airdrop_this_time}</p>
              <h2>total_airdrop</h2>
              <p>{item.total_airdrop}</p>
            </div>

            <div className="fl">
              <h2>Dealer</h2>
              <CardItem cardNumber={item.dealer} />
              <h2>player_result</h2>
              <p>{item.player_result}</p>
              <h2>player_score</h2>
              <p>{item.player_score}</p>
            </div>
            <div className="fl">
              <h2>balance_before</h2>
              <p>{item.balance_before}</p>
              <h2>balance_after</h2>
              <p>{item.balance_after}</p>
              <h2>bet_amount</h2>
              <p>{item.bet_amount}</p>
            </div>
            <div className="fl">
              <h2>dealer_score</h2>
              <p>{item.dealer_score}</p>
              <h2>games_played</h2>
              <p>{item.games_played}</p>
              <h2>is_double</h2>
              <p>{item.is_double}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}, isEqual);
const CardItem = memo(({ cardNumber }: { cardNumber: string[] }) => {
  return (
    <div className="cardItemWrap">
      {cardNumber.map((keys) => (
        <div className={`cardItem ${colorStyle(keys)}`} key={keys}>
          <Card cardKeyStr={keys} />
          <h3>{cardNumberStr(keys)}</h3>
        </div>
      ))}
    </div>
  );
}, isEqual);
export default InfoListAll;

import { isEqual } from "lodash";
import { memo } from "react";
import "./Loading.scss";
const Loading = memo(({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) {
    return <></>;
  }
  return (
    <div className="loading-container-inner">
      <div className="loading-animation">
        <img className="loading-circle" src="/img/icon/clubs.svg" />
        <img className="loading-circle" src="/img/icon/hearts.svg" />
        <img className="loading-circle" src="/img/icon/spades.svg" />
        <img className="loading-circle" src="/img/icon/diamonds.svg" />
      </div>
    </div>
  );
}, isEqual);
export default Loading;
